/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;500&display=swap");

*,
*::after,
*::before {
  padding: 0;
  margin-top: 0;
  box-sizing: border-box;
  font: inherit;
}

body {
  max-width: 1920px;
  margin-inline: auto;
  background: var(--black);
  font-family: Outfit;
}

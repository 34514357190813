.modalContent {
  padding: 32px;
  max-height: 50vh;
  overflow-y: scroll;
}

.modalContent > form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.modalContent > form > .postBetContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 32px;
}

.modalContent > form > .postBetContainer::after {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -16px;
  height: 1.5px;
  background: #800000;
  content: "";
}

.betInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lastForm > input {
  border: none;
  outline: none;
  background: none;
  border: 1px solid #800000;
  width: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  padding: 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buttonContainer {
  margin-top: 32px;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}

.buttonCancel,
.buttonSubmitting {
  outline: none;
  border: none;
  background: none;
  width: 100%;
  border: 2px solid black;
  padding-block: 1rem;
}

.betlist {
  width: 70%;
  margin-inline: auto;
  list-style-type: none;
  gap: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 144px;
}

.listitem {
  border: 2px solid black;
  border-radius: 8px;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 150px;
  align-self: flex-start;
  width: 350px;
}

.active {
  border: 2px solid red;
}

.matchname {
  display: flex;
  flex: 0;
  gap: 10px;
}

.matchname > div:nth-child(1) {
  flex: 1;
}

.dateContainer {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.betlistmain {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  font-size: 10px;
}

.betlistmore {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-inline: auto;
  justify-content: space-between;
  gap: 10px;
  font-size: 10px;
}

.seemore {
  font-weight: 300;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.modalContent {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
}

.modalContent > h1 {
  font-size: 32px;
}

.modalContent > form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
}

.modalContent > form > input {
  border: none;
  outline: none;
  padding: 16px;
  border-bottom: 1px solid black;
  height: 24px;
  width: 100%;
}

.modalContent > form > input::placeholder {
  font-weight: 300;
}

.modalContent > .error {
  color: red;
  text-align: center;
}

.modalContent > .modalLast {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  text-align: center;
}

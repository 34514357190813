header {
  max-width: 1920px;
  margin-inline: auto;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  background: red;
  z-index: 5;
}

header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  height: 2px;
}

@media (max-width: 600px) {
  header {
    padding: 16px;
  }
}

@media (max-width: 480px) {
  header {
    padding-left: 8px;
  }
}

header > a > .logo {
  width: 150px;
  display: block;
}

.buttonContainer {
  display: flex;
  gap: 20px;
}

.buttonContainer > button {
  width: 100px;
}

@media (max-width: 600px) {
  .buttonContainer {
    display: flex;
    gap: 15px;
  }

  .buttonContainer > button {
    width: 75px;
  }
}

@media (max-width: 480px) {
  .buttonContainer {
    display: flex;
    gap: 10px;
  }
}

.loggedIn {
  display: flex;
  gap: 16px;
  align-items: center;
}

.cart {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 2px solid white;
  border-radius: 8px;
  padding: 10px;
  width: 80px;
}

.cart > div {
  color: white;
  background: red;
  padding: 4px;
  border-radius: 8px;
}

.profile {
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  text-align: center;
}
.profile > div:nth-child(1) {
  padding-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile > .profileul {
  position: absolute;
  top: 100%;
  list-style-type: none;
  transform: translateX(-50%);
  background: white;
  color: black;
  font-size: 12px;
  border-radius: 8px;
  border: 1px solid black;
  z-index: 999;
}

.profileul > li {
  padding: 8px;
  position: relative;
}

.profileul > li:not(:last-child)::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 1px;
  width: 100%;
  background-color: black;
}

.profileul > li > a {
  text-decoration: none;
  color: black;
}

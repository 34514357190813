.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}
.modal {
  background: white;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 20;
  max-width: 450px;
  width: 45%;
  margin-inline: auto;
  overflow: hidden;
}

@media (max-width: 768px) {
  .modal {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .modal {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .modal {
    width: 90%;
  }
}

.eachBet {
  position: relative;
  padding: 4px;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 100px;
  cursor: pointer;
}

.active {
  border: 1.5px solid red;
}

.plus,
.minus {
  cursor: pointer;
  position: absolute;
  right: 4px;
  bottom: 4px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
}

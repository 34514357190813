.button {
  cursor: pointer;
  padding-block: 1rem;
  width: 100%;
  background: red;
  border: 2px solid white;
  color: white;
  outline: none;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.2);
}

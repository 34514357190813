.footer {
  max-width: 1920px;
  width: 100%;
  margin-inline: auto;
  position: fixed;
  bottom: 0;
  background: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

@media (max-width: 600px) {
  .footer {
    padding-inline: 16px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding-left: 8px;
  }
}

.footer > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  font-weight: 300;
}

.logo {
  width: 100px;
  display: block;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 8px;
  list-style-type: none;
  margin-bottom: 0;
}

.navigation > li > a {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

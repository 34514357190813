.modalContent {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
}

.modalContent > h1 {
  font-size: 32px;
}

.modalContent > form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
}

.modalContent > form > input {
  border: none;
  outline: none;
  padding: 16px;
  border-bottom: 1px solid black;
  height: 24px;
  width: 100%;
}

.modalContent > form > input::placeholder {
  font-weight: 300;
}

.modalContent > .error {
  color: red;
  text-align: center;
}

.modalContent > .modalLast {
  display: flex;
  flex-direction: column;
  font-weight: 300;
  text-align: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modalContent {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
  z-index: 30;
}
